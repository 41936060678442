import React from "react";

import posed from "react-pose";
import catAndHumanIllustration from "../images/cat-and-human-illustration.svg";

const Transition = posed.div({
  enter: { opacity: 1, delay: 300, beforeChildren: true },
  exit: { opacity: 0 }
});

const WorkInProgress = () => (
  <Transition>
    <div className="text-center">
      <img
        src={catAndHumanIllustration}
        className="block mx-auto w-1/2"
        alt="Cat and human sitting on a couch"
      />

      <h2 className="bg-yellow inline-block my-8 p-3">Work In Progress</h2>
    </div>
  </Transition>
);

export default WorkInProgress;
