import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import posed from "react-pose";

const handleClick = ev => {
  ev.preventDefault();
  const element = document.getElementById("nav");
  element.classList.toggle("block");
  element.classList.toggle("hidden");
};

const NavButton = posed.div({
  hoverable: true,
  pressable: true,
  init: {
    y: 0
  },
  hover: {
    y: -5
  },
  press: {
    scale: 1.1
  },
  transition: {
    x: { type: "spring" }
  }
});

const Header = ({ siteTitle }) => (
  <nav className="bg-white">
    <div className="flex flex-wrap items-center justify-between mx-auto p-4 md:p-8">
      <Link
        to="/"
        className="flex items-center no-underline text-purple-dark font-extra"
      >
        <svg
          className="fill-current h-8 mr-2 w-8"
          width="54"
          height="54"
          viewBox="0 0 54 54"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M13.5 22.1c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05zM0 38.3c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05z" />
        </svg>
        <span className="font-bold text-3xl tracking-tight">{siteTitle}</span>
      </Link>

      <button
        className="block md:hidden border border-white flex items-center px-3 py-2 rounded text-purple-dark"
        onClick={handleClick}
      >
        <svg
          className="fill-current h-3 w-3"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </button>

      <div id="nav" className="hidden md:flex md:items-center w-full md:w-auto">
        <div className="text-2xl font-extra">
          <Link
            to="/games"
            className="block mt-4 md:inline-block md:mt-0 mr-6 no-underline text-purple-dark hover:text-purple-darker"
          >
            <NavButton>Games</NavButton>
          </Link>

          <Link
            to="/apps"
            className="block mt-4 md:inline-block md:mt-0 mr-6 no-underline text-purple-dark hover:text-purple-darker"
          >
            <NavButton>Apps</NavButton>
          </Link>

          <Link
            to="/about"
            className="block md:inline-block mt-4 md:mt-0 mr-6 no-underline text-purple-dark hover:text-purple-darker"
          >
            <NavButton>About</NavButton>
          </Link>

          <Link
            to="/contact"
            className="block md:inline-block mt-4 md:mt-0 no-underline text-purple-dark hover:text-purple-darker"
          >
            <NavButton>Contact</NavButton>
          </Link>
        </div>
      </div>
    </div>
  </nav>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
